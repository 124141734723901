import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

// Carregamento dinâmico dos componentes
const HomePage = React.lazy(() => import('./pages/HomePage'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const MinhaDieta = React.lazy(() => import('./pages/MinhaDieta'));
const JogoDoVelho = React.lazy(() => import('./pages/JogoDoVelho'));
const MinhasFinancas = React.lazy(() => import('./pages/MinhasFinancas'));
const Deletion = React.lazy(() => import('./pages/Deletion'));
const Kronos = React.lazy(() => import('./pages/Kronos'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <Router>
      <div className={`app ${isDarkMode ? 'dark' : 'light'}`}>
        <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />

        {/* Suspense com Spinner de carregamento */}
        <Suspense
          fallback={
            <div className="loading-container">
              <div className="spinner"></div>
              <div className="loading-text">Carregando...</div>
            </div>
          }
        >
          <main>
            <Routes>
              <Route path="/" element={<HomePage isDarkMode={isDarkMode} />} />
              <Route path="/kronos" element={<Kronos isDarkMode={isDarkMode} />} />
              <Route path="/jokenpo" element={<PrivacyPolicy isDarkMode={isDarkMode} />} />
              <Route path="/minha-dieta" element={<MinhaDieta isDarkMode={isDarkMode} />} />
              <Route path="/jogo-do-velho" element={<JogoDoVelho isDarkMode={isDarkMode} />} />
              <Route path="/minhas-financas" element={<MinhasFinancas isDarkMode={isDarkMode} />} />
              <Route path="/exclusao-de-conta" element={<Deletion isDarkMode={isDarkMode} />} />
              <Route path="*" element={<PageNotFound isDarkMode={isDarkMode} />} />
            </Routes>
          </main>
        </Suspense>

        <Footer isDarkMode={isDarkMode} />
      </div>
    </Router>
  );
}

export default App;
